.tela-finalizado {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }
}