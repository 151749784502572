.pagina-treinopratico-lista {
    overflow: auto;

    &>h2 {
        color: #8A8A8A;
    }

    &>h1 {
        margin-bottom: 80px;
        color: #D3C314;
        cursor: default;
    }


    &>section {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        
        width: 700px;

        h3 {
            color: #fff;
            margin-bottom: 30px;
            cursor: default;
        }
    }
}