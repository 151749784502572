.listaTreino_Principal {

    .conteudo {
        display: flex;
        flex-direction: column;
        width: 525px;
        gap: 3em;

        .titulo_Lista {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;

            img {
                width: 2em;
                cursor: pointer;
            }
        }

        .listagem_treinos {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 1em;
        }
    }
}