.acompanhamento_cla_admin {
  
  main {
    > .clas {
      display: flex;
      flex-flow: row wrap;
      gap: 30px 50px;
      min-height: 420px;
      width: calc(100vw - 500px);

      @media (max-width: 1024px) {
        width: 100%;
      }
    }
  }
}
