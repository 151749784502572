.treino-teorico-treino {

    .treinos-container {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;


        .conteudo {
            width: 80%;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;


            .titulos {
                width: 100%;

                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                
                .sub {
                    font-family: 'Poppins-Bold';
                    font-size: 20px;
                    color: #8A8A8A;
                }

                .principal {
                    font-size: 34px;
                    font-family: 'Poppins-Bold';
                    color: #D3C314;
                    cursor: default;
                    margin-bottom: 80px;
                }

            }

            .cards-container {
                width: 100%;

                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                gap: 30px;


                .group-items {
                    height: auto;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                }

                &>div:nth-child(1) {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    font-size: 24px;
                    font-family: 'Poppins-SemiBold';
                }
            }

        }

    }
    @media (max-width: 1024px) {
        .treinos-container {
            overflow: auto;
            .cards-container{
                
                .group-items{
                    width: 700px;
                }
            }
            align-items: center;
        }
    }
}