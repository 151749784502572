.treino-quiz-controller {
    background-color: #121212;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    
    display: flex;
    flex-direction: column;
    justify-content: center;

    min-height: 100vh;
    font-family: 'Poppins';

    //* {
    //    border: 1px solid green;
    //}
}