@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta&display=swap');

* {
    margin: 0px;
    box-sizing: border-box;
}

input,select,textarea,button,label {
    font-family: inherit;
}


.Toastify * {
    font-family: sans-serif !important;
}


body.light::-webkit-scrollbar {
width: 8px;
height: 8px;
background-color: transparent;
}

body.light::-webkit-scrollbar-thumb {
background: #555555; 
}

body.light::-webkit-scrollbar-thumb:hover {
background: #6b6b6b; 
}



body.dark::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent;
}

body.dark::-webkit-scrollbar-thumb {
    background: #303030; 
}

body.dark::-webkit-scrollbar-thumb:hover {
    background: #6b6b6b; 
}

body.dark::-webkit-scrollbar-track {
    background-color: #171717;
}