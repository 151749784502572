.tela-analisando {
    min-height: 100vh;
    
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .container {
        width: 100%;
        min-height: 85vh;

        display: flex;
        align-items: center;
        justify-content: center;

        .descricao {
            width: 20%;
            
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
    
            padding: 25px;
            gap: 20px;
    
        }
    
        .timer{
            width: 70px;
            height: 70px;
    
            display: flex;
            justify-content: center;
            align-items: center;
    
            border-radius: 100%;
            background-color: #272525;
            box-shadow: 0px 0px 13px #ffe3528a;
        
            font-size: 20px;
            font-weight: 600;
            color: #B89B19;
        }
    
        .imagem {
            width: 70%;

            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: 90%;
                height: 100%;
                max-width: 1200px;
                max-height: 800px;
            }
            
            svg {
                margin: auto;
                display: block;
                shape-rendering: auto;
                width: 6rem;
                height: 6rem;
            }
        }
    }   
}

@media (max-width: 720px) {
    .tela-analisando{
        width: 100%;
        
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        
        padding: 0px 15px;
        gap: 20px;

        .container{
            flex-direction: column;
            .descricao {
                width: 100%;
                min-height: auto;
            }
            
            .imagem {
                width: 100%;
                min-height: auto;
                
                margin-bottom: 15px;
                img{
                    width: 90%;
                    min-width: 300px;
                }
            }
        }
    }
}