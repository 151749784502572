.conteudo-novo-admin {
    >.player {
      display: none;
    }

    & > h2 {
        color:#3973BA; 
        font-size:20px;
    }

    & > h1 {
        color:#31332B;
        font-size:34px;
        margin-bottom: 45px;
        margin-top: 20px;
    }

    .botoes button {
        position: absolute;
        right: 90px;
        bottom: 30px;

        padding:23px 55px;
        background:#3A73B9;
    }

    .botoes .novo {
        right: 210px;
    }

    @media (max-width: 1024px) {
        .botoes {
            margin-top: 50px;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            gap: 10px
        }
        .botoes button {
            position: static;
        }
    }


    .conteudo-inputs {
        display: flex;
        flex-flow: column nowrap;
        width: 325px;
        gap: 10px;

        .correcao {
            >label {
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: #444242;
                user-select: none;
                margin-left: 5px;
            }
        }
    }



    
}