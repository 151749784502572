.tela-analiseEntrega {

    width: 100%;
    height: 100%;
    
    .container{
        width: 100%;
        
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        gap: 50px;
    }
    
    //*{
    //    border: 1px solid green;
    //}


    @media (max-width: 768px) {
        .container{
            gap: 150px;
        }
    }

}