.treino-pratico-novo-admin {
    overflow-x: hidden;
    
    div > h2:nth-child(1) {
        color:#3973BA;
        margin-bottom: 0em;
    }

    h2 {
        color:#3973BA;
        margin-bottom: 1.25em; 
    }

    h1 {
        color:#31332B;
        margin-bottom: 80px;
    }

    h3 {
        color:#000000;
        margin-bottom: 1.2em;
        cursor: unset;
    }

    .treino-pratico-inputs {
        display: flex;
        flex-direction: row;
        width: 100%;

        padding-bottom: 2em;


        section { 
            width: 50%;
            
            div { 

                .botao { 
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 2em;
                    gap: 1em;
                } 

            }

            .agrupamento-anexo {
                position: relative;
            }

            .lixeira-anexo {
                position: absolute;
                right: -2em;
                top: 1em;
                cursor: pointer;
            }

            .arquivos-adicionados{
                display: flex;
                flex-direction: row;
                align-items: center;

                .botao-arquivo {
                    margin-right: .5em;
                }
            }

            .agrupamento-teste {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                height: 15em;
            }

            .botao-adicionar {
                display: flex;
                justify-content: flex-end;
                margin-top: .5em;
            }

            .animacao-teste {
                animation: fadeInRight;
                animation-duration: 1.3s;
            }
        }

        section:nth-child(1) {
            margin-right: 5%;

            div {
                margin-bottom: .5em;
            }
        }
    }

    @media (max-width: 1024px) {
        padding-bottom: 0px;

        .treino-pratico-inputs {
            display: flex;
            flex-direction: column;
        
            .section {
                margin-right: 0px;

                textarea {
                    height: 80%;
                }
            }

            section { 
                width: 100%;

                .agrupamento-teste { 
                    display: flex;
                    flex-direction: column;
                    height: 20em;

                    div {
                        width: 100%;
                        margin-bottom: .5em;
                    }
                }

                .botao-adicionar {
                    margin-bottom: 1em;
                }

                .animacao-teste {
                    .botao {
                        //margin-right: 1em;
                    }
                }
            }
        }
    }
}      
