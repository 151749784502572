.treinoteorico-espiral-admin {

    .espirais {
        display: flex;
        flex-flow: row wrap;
        gap: 30px 50px;
        min-height: 450px;
        width: calc(100vw - 500px);
    }

    & > h1 {
        margin-bottom: 80px;
        color: #3973BA;
    }

    & > h3 {
        color:#000; 
        font-size: 22px;
        margin-bottom: 25px;
    }

    @media (max-width: 1024px) {
        overflow-y: scroll;
        .espirais {
            justify-content: center;
            width: 100%;
        }
    }



}