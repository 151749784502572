
.pagina-not-found {
    width: 100%;
    height: 100vh;
    
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    
    background: url('../../../../public/assets/images/backgrounds/montanhas.svg') no-repeat;
    background-size: cover;
    overflow-x: hidden;
    
    color: #fff;
    
    * {
        font-family: 'Mukta';
    }

    .monge > img{
        width: 470px;
        animation: float 4s ease-in-out infinite;
    
    }

    @keyframes float {
        0%, 100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-10px);
        }
    }

    .textos {
        height: 90vh;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-end;
        position: relative;
        
        span {
            font-size: 185px;
            margin-right: 1em;
        }

        .nuvens {
            position: absolute;
            top: 4em;
            right: 5em;
            animation: float 4s ease-in-out infinite;
        }

        .botoes-texto {
            height: 30vh;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-end;

            span {
                text-align: end;
                width: 70%;
                font-size: 27px;
            }
            .botao {
                margin-top: 2em;
                border: 3px solid #da7a0c;
                transition: .3s;
                font-weight: 500;
            }
            .botao:hover {
                transition: .3s;
                background-color: rgba(0, 0, 0, 0);
                border-color: #da7a0c;
            }
        }
    }
}

@media (max-width:1250px) {
    .pagina-not-found {
        
        .monge {
            width: 350px;
            animation: float 4s ease-in-out infinite;
        
        }
        .textos {
            span {
                font-size: 170px;
            }
        }

    }
}

@media (max-width:1024px) {
    .pagina-not-found {
        flex-direction: column-reverse;
        justify-content: center;
        overflow: scroll;

        .monge {
            display: flex;
            justify-content: center;
            margin-bottom: 5em;
            padding: 3em;
            img {
                width: 400px;
            }
        }

        .textos {
            justify-content: center;
            align-items: center;
            
            
            span {
                font-size: 185px;
                margin: 0;
            }
    
            .nuvens {
                top: 1em;
                right: 8em;
            }
    
            .botoes-texto {
                height: 30vh;
                align-items: center;
    
                span {
                    text-align: center;
                    width: 50%;
                    font-size: 33px;
                }
                
                .barrinha {
                    & > img {
                        display: none;
                    }
                }
                
                .botao {
                    margin-top: 2em;
                    font-size: 18px;
                }
            }
        }
    }
}

@media (max-width:800px) {
    .pagina-not-found {
        overflow: scroll;
        .monge {
            padding: 3em;
        }
        .textos {
            .nuvens {
                top: 1em;
                right: 8em;
            }
        }
    }
}

@media (max-width:575px) {
    .pagina-not-found {
        
        .textos {
            span {
                font-size: 125px;
                margin: 0;
            }
            .nuvens {
                width: 300px;
                top:2em;
                right: 6em;
            }
            .botoes-texto {
                height: 25vh;
                span {
                    font-size: 22px;
                }
            }
        }
    }
}

@media (max-width:475px) {
    .pagina-not-found {
        
        .monge {
            img {
                width: 300px;
            }
        }

        .textos {
            span {
                font-size: 155px;
                margin: 0;
            }

            .nuvens {
                width: 320px;
                top:3em;
                right: 2em;
            }

            .botoes-texto {
                height: 25vh;
                
                span {
                    font-size: 25px;
                }
            }
        }
    }
}

@media (max-width:375px) {
    .pagina-not-found {
        overflow: scroll;

        .monge {
            padding: 3em;
            img {
                width: 265px;
            }
        }

        .textos {
            span {
                font-size: 125px;
                margin: 0;
            }

            .nuvens {
                width: 300px;
                top:1em;
                right: 1em;
            }

            .botoes-texto {
                height: 25vh;
                
                span {
                    font-size: 18px;
                }
                .botao {
                    margin-top: 2em;
                    font-size: 16px;    
                }
            }
        }
    }
}