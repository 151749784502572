.tela-aguardando {
    min-height: 100vh;
    
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .container{
        width: 100%;
        height: 85vh;
    
        display: flex;
        justify-content: center;
        align-items: center;
    }
}