@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.acompanhamento-aluno-admin {

  *:disabled {
    cursor: not-allowed;
  }

  .modal>main {
    width: 1024px;
    height: clamp(400px, 80vh, 950px);

    @media (max-width: 1024px) {
      width: 80%;
    }

    >section {
      width: 100%;

      >nav {
        display: flex;
        flex-flow: row nowrap;
        font-size: 18px;
        font-weight: 700;
        color: #B5B5B5;
        gap: 10px;
        user-select: none;
        margin-top: 45px;
  
        >* {
          cursor: pointer;
          transition: .3s ease;
          padding: 5px;

          @media (max-width: 600px) {
            padding: 3px;
            font-size: 16px;
          }

          @media (max-width: 400px) {
            padding: 2px;
            font-size: 14px;
          }
        }
  
        >.selecionado {
          color: #2284FB;
        }
      }
  
      >article {
        margin-top: 60px;
        width: 100%;
        height: 41vh;
        overflow-y: auto;
        display: none;
        user-select: none;
        max-height: 600px;
        padding: 10px;
        min-height: 350px;
  
        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }
  
        &::-webkit-scrollbar-thumb {
          background: #c2c1c1;
          border-radius: 4px;
          cursor: default;
        }
  
        &::-webkit-scrollbar-thumb:hover {
          background: #b9b9b9;
        }

        @media (max-width: 600px) {
          width: 100%;
        }

        @media (max-height: 800px) {
          margin-top: 30px;
        }

        @media (max-height: 750px) {
          height: calc(100vh - 600px);
        }
      }
  
      >.cat1 {
        animation: fade .2s normal ease-in;
        display: block;

        >div {
          min-width: 500px;
        }
      }
  
      >.cat2 {
        animation: fade .2s normal ease-in;
        display: block;
  
        >h3 {
          font-size: 18px;
          color: #1854C2;
          margin-bottom: 10px;
        }
  
        >div {
          margin-bottom: 20px;
          min-width: 500px;
        }

        .i1>div:nth-child(1)>h2, .i3>div:nth-child(1)>h2 {
          width: 155px;
        }
        
        >button {
          margin-left: auto;
        }
      }

  
      >.cat3 {
        animation: fade .2s normal ease-in;
        display: flex;
        flex-flow: column wrap;
        gap: 0 50px;
  
        >div {
          >h3 {
            color: #1854C2;
          }
  
          >div {
            margin-bottom: 50px;
          }
        }
      }
    }


    @media (max-width: 790px) {
      width: 80vw;
    }

    @media (max-width: 600px) {
      padding: 20px;
    }
  }

  .titulo-alterar-icone {
    margin-left: 10px;
  }

  >main {
    width: 100%;

    >.membros {
      margin-top: 90px;
      max-width: 650px;

      >div {
        margin-top: 15px;
        display: flex;
        flex-flow: row wrap;
        gap: 5px;

        >img {
          @media (max-width: 1024px) {
            width: 60px;
            height: 60px;
          }
        }
      }
    }

    >.mensagem {
      margin-top: 125px;
      width: 480px;

      @media (max-width: 550px) {
        width: 100%;
      }

      >.titulo {
        display: inline-flex;        
        flex-flow: row nowrap;
        
        >h2 {
          font-size: 22px;
          width: fit-content;
          margin-right: 10px;
          user-select: none;
        }
      }

      >.inputs {
        margin-top: 15px;
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        gap: 7px;
      }
    }
  }
}
