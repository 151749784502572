.tela-lendo {
    min-height: 100vh;
    
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    position: relative;
    
    overflow-x: hidden;
    
    //*{
    //    border: 1px solid green;
    //}

    .container {
        width: 100%;
        min-height: 85vh;

        display: flex;
        justify-content: center;
        align-items: center;

        .content {
            width: 100%;
            min-height: 50vh;

            display: flex;
            justify-content: center;

            .descricao {
                width: 20%;
                
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
        
                padding: 25px;
                gap: 20px;
            
                .botoes{
                    width: 100%;
                    
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row;
                    gap: 5%;

                    button{
                        width: 150px;
                    }
                }
            }

            .imagem {
                width: 70%;
        
                display: flex;
                justify-content: center;
                align-items: center;

                img{
                    width: 90%;
                }       
            }
        }
    }    
}
@media (max-width: 720px) {
    .tela-lendo{
        width: 100%;
        
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        
        gap: 20px;
        padding: 0px 15px;

        .container{
            .content{
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .descricao {
                    width: 100%;
                    min-height: auto;
                }
            
                .imagem {
                    width: 100%;
                    min-height: auto;
                    
                    margin-bottom: 15px;
                    
                    img{
                        width: 90%;
                        min-width: 300px;
                    }
                }
            }
        }
    }
}