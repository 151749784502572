.acompanhamento_espiral_admin {
  .sincronizar-espiral-modal>main {
    width: 620px;

    @media (max-width: 790px) {
      width: 80%;
    }

    >section {
      >div {
        display: flex;
        width: 100%;
        flex-flow: row nowrap;
        justify-content: space-evenly;
        gap: 20px;
        margin: 25px 0 35px 0;

        input[type=number] {
          -webkit-appearance: textfield;
             -moz-appearance: textfield;
                  appearance: textfield;
        }
        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button { 
          -webkit-appearance: none;
        }

        @media (max-width: 600px) {
          flex-flow: column nowrap;          
          gap: 10px;
        }
      }
    }
  }

  .sincronizar-servidor-modal>main {
    width: 620px;

    @media (max-width: 790px) {
      width: 80%;
    }

    @media (max-width: 550px) {
      padding: 20px;
    }


    .inputs {
      margin-top: 35px;
      background: #fff;
      border-radius: 8px;
      width: 100%;
      border: 1px solid #ACACAC;
      padding: 25px 30px;

      h1 {
        font-size: 14px;
        font-weight: 700;
        user-select: none;
        margin-bottom: 5px;
      }

      >div>div {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        gap: 15px;

        >div {
          display: flex;
          flex-flow: column nowrap;
          width: 100%;

          >h2 {
            font-size: 14px;
            width: 100%;
            text-align: right;
            height: 23px;
            user-select: none;

            @media (max-width: 790px) {
              height: 46px;
            }
          }

          >input {
            width: 100%;
            height: 23px;
            outline: none;
            border: none;
            border: 0 0 1px 0 solid #000;
            font-size: 14px;
            text-decoration: underline 1px solid;

            @media (max-width: 790px) {
              margin-bottom: 23px;
            }
          }
        }
      }
    }

    .button {
      text-align: right;
      display: inline-flex;
      justify-content: flex-end;
      width: 100%;
      margin-top: 30px;
    }
  }

  .sincronizar-membros-modal>main {
    width: 620px;

    @media (max-width: 790px) {
      width: 80%;
    }

    .botao {
      margin-top: 25px;
    }
  }

  main {
    width: 100%;

    >.cla-titulo {
      margin-bottom: 95px;

      >div {
        display: inline-flex;
        align-items: center;
        color: #31332B;
        font-size: 14px;
        font-weight: 700;
        gap: 7px;
        cursor: pointer;
        user-select: none;

        >img {
          -webkit-user-drag: none;
        }
      }
    }

    >.membros {
      margin-bottom: 90px;
      width: calc(100vw - 500px);

      @media (max-width: 1024px) {
        width: 100%;
      }

      >.titulo {
        display: inline-flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        >input {
          width: 140px;
          height: 30px;
          outline: none;
        }
      }

      >.carrossel {
        @media (max-width: 1024px) {
          width: 100%;
          max-height: 400px;
          overflow: auto;

          &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
          }
    
          &::-webkit-scrollbar-thumb {
            background: #c2c1c1;
            border-radius: 4px;
            cursor: default;
          }
    
          &::-webkit-scrollbar-thumb:hover {
            background: #b9b9b9;
          }
        }
      }
    }

    >.espirais {
      margin-bottom: 125px;
      width: calc(100vw - 500px);
      
      @media (max-width: 1024px) {
        width: 100%;
      }

      >div {
        width: 100%;

        >div {
          height: 300px;
        }

        @media (max-width: 1024px) {
          width: 100%;
          max-height: 400px;
          overflow: auto;

          > div {
            height: auto;
          }

          &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
          }
    
          &::-webkit-scrollbar-thumb {
            background: #c2c1c1;
            border-radius: 4px;
            cursor: default;
          }
    
          &::-webkit-scrollbar-thumb:hover {
            background: #b9b9b9;
          }
        }
      }
    }
  }
}
