.tela-correcao {
    min-height: 100vh;
    
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    
    .container{
        width: 100%;
        min-height: 85vh;

        display: flex;
        justify-content: center;
        align-items: center;
        
        .content{
            width: 100%;
            min-height: 60vh;
            display: flex;
            justify-content: space-evenly;

            & > div:nth-child(3) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        
            .descricao {
                width: 20%;
                
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
        
                padding: 25px;
                gap: 20px;
        
            }
    
            .video-container {
                width: 70%;
                max-width: 1024px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-grow: 1;
                padding: 25px;
                
                svg {
                    margin: auto;
                    display: block;
                    shape-rendering: auto;
                    width: 6rem;
                    height: 6rem;
                }
            }
        }
    }
}
@media (max-width: 720px) {
    .tela-correcao{
        width: 100%;
        
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        
        padding: 0px 15px;
        gap: 20px;
        
        .container{
            .content{
                flex-direction: column;
                .descricao {
                    width: 100%;
                    min-height: auto;
                }
                
                .video-container {
                    width: 100%;
                    height: 400px;
        
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}