.unauthorized-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    width: 100%;
    height: 100vh;
    
    background: url('../../../../public/assets/images/backgrounds/montanhas.svg') no-repeat;
    background-size: cover;

    overflow: hidden;

    * {
        font-family: 'Mukta';
    }


    .unauthorized-text {
        width: 25%;
        height: 100vh;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        color: #fff;
        z-index: 5;

        .titulo {    
            font-size: 135px;
            font-weight: 500;
            height: 25vh;
        }
        span {
            font-size: 22px;
        }
        .botao {
            margin-top: 2em;
            border: 3px solid #da7a0c;
            transition: .3s;
            font-weight: 500;
        }
        .botao:hover {
            transition: .3s;
            background-color: rgba(0, 0, 0, 0);
            border-color: #da7a0c;
        }
    }
    .unauthorized-images{
        height: 100vh;
        display:flex;
        justify-content: center;
        position: relative;
        .templo-shaolin {
            width: 1125px;
            position: absolute;
            bottom: 0px;
            z-index: 1;

        }
        .monges-barras {
            display: flex;
            justify-content: center;
            position: relative;
            
            .monges {
                position: absolute;
                z-index: 4;
                bottom: 10px;
            }

            
            .barra-esquerda {
                position: absolute;
                right: 1em;
                bottom: -4.5em;
                animation: subindo-esquerda 1.5s ease;
                animation-direction: alternate;
                animation-fill-mode: forwards;
                z-index: 3;
            }
            .barra-direita {
                position: absolute;
                left: 1em;
                bottom: -4.5em;
                animation: subindo-direita 1.5s  ease;
                animation-direction: alternate;
                animation-fill-mode: forwards;
                z-index: 3;
            }
    
            @keyframes subindo-direita {
                0% {
                  transform: translate(2, 1);    
                }
                100% {
                  transform: translate(-100px, -60px);
                }
              }
    
            @keyframes subindo-esquerda {
                0% {
                  transform: translate(1, 2);    
                }
                100% {
                  transform: translate(100px, -60px);
                }
              }
        }

        .estrela-1 {
            position: absolute;
            bottom: 40em;
            right: 20em;
            z-index: 1;
            animation: pulse 2s infinite;
            animation-direction: alternate;
        }
        .estrela-2 {
            position: absolute;
            bottom: 30em;
            left: 20em;
            z-index: 1;
            animation: pulse 3s infinite;
            animation-direction: alternate-reverse;
        }

        @keyframes pulse {
            0% {
                transform: scale(1);
                filter: brightness(0%);
            }
            100% {
                transform: scale(1.1);
                filter: brightness(200%);
            }
        }
        
    }       
}

@media (max-width:1200px) {
    .unauthorized-main {
        .unauthorized-text {
            width: 35%;
        }
        .unauthorized-images {
            .templo-shaolin {
                width: 1000px;
           .monges-barras {
               .monges {
                   width: 450px;
               }
               .barra-direita{
                width: 300px;
               }
           }
            }
        }
    }   
}

@media (max-width: 1024px) {
    .unauthorized-main {

        .unauthorized-text {
            width: 40%;
        }

        .unauthorized-images {
            .templo-shaolin {
                width: 900px;
            }
            .monges-barras {
                .monges {
                    width: 260px;
                }
                .barra-direita {
                    width: 320px;
                    left: 2.5em;
                }
                .barra-esquerda {
                    width: 320px;
                    right: 2.5em;
                }
            }
        }
    }
}

@media (max-width: 870px) {
    .unauthorized-main {

        .unauthorized-text {
            width: 50%;
        }
        .unauthorized-images {
            .templo-shaolin {
                width: 850px;
            }
            .monges-barras {
                .monges {
                    width: 260px;
                }
                .barra-direita {
                    width: 310px;
                    left: 2.5em;
                }
                .barra-esquerda {
                    width: 310px;
                    right: 2.5em;
                }
            }
            
        }
    }
}


@media (max-width: 768px) {
    .unauthorized-main {

        .unauthorized-text {
            width: 50%;
            span {
                font-size: 18px;
            }
            .botao {
                font-size: 16px;
            }
        }
        .unauthorized-images {
            .templo-shaolin {
                width: 700px;
            }
            .monges-barras {
                .monges {
                    width: 230px;
                }
                .barra-direita {
                    width: 290px;
                    left: 2.5em;
                }
                .barra-esquerda {
                    width: 290px;
                    right: 2.5em;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .unauthorized-main {

        .unauthorized-text {
            width: 70%;
            span {
                font-size: 18px;
            }
            .botao {
                font-size: 16px;
            }
        }
        .unauthorized-images {
            .templo-shaolin {
                width: 650px;
            }
            .monges {
                width: 230px;
            }
            .barra-direita {
                width: 290px;
                left: 2.5em;
            }
            .barra-esquerda {
                width: 290px;
                right: 2.5em;
            }
        }
    }
}

@media (max-width: 375px) {
    .unauthorized-main {

        .unauthorized-text {
            width: 80%;
            span {
                font-size: 18px;
            }
            .botao {
                font-size: 16px;
            }
        }
        .unauthorized-images {
            .templo-shaolin {
                width: 650px;
            }
            .monges {
                width: 230px;
            }
            .barra-direita {
                width: 290px;
                left: 2.5em;
            }
            .barra-esquerda {
                width: 290px;
                right: 2.5em;
            }
        }
    }
}