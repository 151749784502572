.conteudo-cla {
    overflow: auto;

    .cla > h2 {
        font-size: 16px;
    }

    .conteudo_cla-sub {
        .clas {
            display: flex;
            flex-flow: row wrap;
            gap: 30px 50px;
            min-height: 420px;
            width: 900px;
        }

        &>h1 {
            margin-bottom: 80px;
            cursor: default;
        }
    }

    @media (max-width: 1440px) {
        .conteudo_cla-sub {
            max-width: 100%;

            .clas {
                max-width: 62vw;
            }

        }
    }

    @media (max-width: 1024px) {

        .conteudo_cla-sub {
            width: 100%;

            .clas {
                max-width: 100%;
                width: 100%;
                justify-content: center;
                padding: 1em;

                .comp-carrossel-espiral {
                    overflow: hidden;
                }
            }
        }
    }



}