.tela-respondendo {
    min-height: 100vh;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    .container{
        width: 100%;
        min-height: 85vh;
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 60px;

        &:last-child {
            padding: 50px 0px;
        }
    
        .timer-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
    
            .texto {
                font-size: 36px;
                font-weight: 600;
                color: white;
            }
    
            .barra {
                width: 100%;
                height: 13px;
                border-radius: 40px;
                border: 1px solid #B7BB0A;
                display: flex;
    
                div {
                    background-color: #B7BB0A;
                    border-radius: 40px;
                }
    
            }
        }
    
    
        .alternativas {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 40px;
    
            .selected {
                transform: scale(1.1, 1.1);
                background-color: #434343;
                color: white;
                z-index: 1;
            }

            .idle{
                background-color: #fffffff2;
            }
    
            .alternativa-texto {
                width: 300px;
                height: 200px;
    
                cursor: pointer;
    
                display: flex;
                justify-content: flex-start;
                align-items: center;
                text-align: center;
    
                transition: .4s;
    
                font-size: 20px;
                font-weight: 500;
                
                .alt{
                    display: flex;
                    justify-content: center;
                    
                    width: 100%;
                    height: 100%;
                    
                    overflow-y: auto;
                    
                    &::-webkit-scrollbar {
                        width: 6px;
                        border-radius: 999px;
                    }
                    
                    &::-webkit-scrollbar-thumb {
                        background: #414141; 
                        border-radius: 10px;
                    }
                    
                    &::-webkit-scrollbar-thumb:hover {
                        background: #555555; 
                    }
                }
            }
    
            .alternativa-imagem {
                width: 40%;
                height: 400px;
                text-align: center;
                background-color: #404040;
                transition: .4s;
                cursor: grab;

            }
        }
    }
}
@media (max-width: 1024px) {
    .tela-respondendo {
        padding: 0px 15px;
        .alternativas {
            .alternativa-imagem{
                width: 350px;
                height: 250px;
            }
        }
    }
}

@media (max-width: 720px) {
    .tela-respondendo {
        .container{
            .alternativas {
                flex-direction: column;
                .alternativa-imagem{
                    width: 100%;
                    max-width: 400px;
                    height: 200px;
                }
            }
        }
    }
}