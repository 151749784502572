.treinoteorico_cla_admin {
overflow: auto;
.treinoteorico_cla_admin-sub{
    .clas {
            display: flex;
            flex-flow: row wrap;
            gap: 30px 50px;
            min-height: 420px;
            width: 900px;
        }

        &>h1 {
            margin-bottom: 80px;
        }
    }

    @media (max-width: 1440px) {
        .treinoteorico_cla_admin-sub{
            max-width: 100%;
            .clas {
                max-width: 62vw;
            }

        }
    }

    @media (max-width: 1024px) {
        
        .treinoteorico_cla_admin-sub{
            width: 100%;
            .clas {
                max-width: 100%;
                width: 100%;
                justify-content: center;
                padding: 1em;

                .comp-carrossel-espiral{
                    overflow: hidden;
                }
            }
        }
    }



}

