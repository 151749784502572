.TreinoTeorico_Quiz_Novo {

    position: relative;

    .titulo_cla {
        color: #3973BA;
    }


    p {
        font-weight: 700;
        font-size: 10px;
        color: #828282;
    }

    .imagem-fullscreen{
        position: absolute;
        width: 90%;
        height: auto;
        z-index: 5;
    }

    .conteudo_quiz {
        display: flex;
        flex-direction: column;

        width: 100%;

        .conteudo_geral {
            display: flex;
            flex-direction: column;

            .conteudo_titulo{
                .titulos{
                    width: fit-content;
                }
            }

            .conteudo_pergunta {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;

                padding-top: 4em;
                gap: 100px;

                .quiz {
                    width: 35%;
                    min-width: 200px;

                    .quiz_pergunta {
                        display: flex;
                        flex-direction: column;
                        gap: 0.5em;

                        .inputs-tipo-quiz>.tempo_pergunta {
                            margin-top: 0.5em;
                        }



                        .alternativas-tipos-espaço>div {
                            margin-top: 0.5em;
                        }
                    }

                    .tempo_pergunta {
                        display: flex;
                        flex-direction: row;
                        gap: 0.5em;
                    }

                    .titulo_alternativas {
                        margin-top: 1em;
                    }

                    .alinhamento_imagem_input {
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        justify-content: flex-start;
                        position: relative;
                        gap: 10px;


                        input[type="checkbox"] {
                            display: flex;
                            position: absolute;
                            right: -1.7em;
                            top: 0em;
                            appearance: none;
                            background-color: #fff;
                            margin: 0;
                            width: 1.4em;
                            height: 1.4em;
                            border: 1px solid #0D7D47;
                            border-radius: 100%;
                            cursor: pointer;
                        }

                        input[type="checkbox"]:checked {
                            background-color: #0D7D47;
                            clip-path: polygon(12% 42%, 0% 63%, 48% 98%, 98% 14%, 78% 0%, 41% 60%);
                            cursor: pointer;
                        }


                    }

                    .alinhamento_input_checkbox {
                        display: flex;
                        flex-direction: row;
                        position: relative;
                    }

                    .alternativas_input {
                        display: flex;
                        position: relative;
                        flex-direction: column;
                        justify-content: space-between;



                        input[type="checkbox"] {
                            display: flex;
                            position: absolute;
                            right: -1.7em;
                            top: 0.3em;
                            appearance: none;
                            background-color: #fff;
                            margin: 0;
                            width: 1.4em;
                            height: 1.4em;
                            border: 1px solid #0D7D47;
                            border-radius: 100%;
                            cursor: pointer;
                        }

                        input[type="checkbox"]:checked {
                            background-color: #0D7D47;
                            clip-path: polygon(12% 42%, 0% 63%, 48% 98%, 98% 14%, 78% 0%, 41% 60%);
                            cursor: pointer;
                        }


                    }

                    .alternativas_input_imagem {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        width: 100%;
                        height: 7em;
                        max-height: 100%;
                        background-color: #e6e4e4;
                        border-radius: 2px;
                        cursor: pointer;
                        transition: all 0.3s;

                        .upload-alternativa-icon {
                            width: 100%;
                            height: 100%;
                            cursor: pointer;
                        }

                        .imagem_selecionada {
                            max-width: 100%;
                            max-height: 100%;
                        }

                        input[type="file"] {
                            visibility: hidden;
                            position: absolute;
                        }

                        &:hover {
                            height: 12em;
                            transition: all 0.4s;
                        }
                    }
                }
            }

            .arquivos {
                display: flex;
                flex-direction: column;
                gap: 5em;
                width: 510px;

                .arquivos_posicionamento{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    width: 100%;
                    h3{
                        align-self: flex-start;
                    }
                }

                .botoes_alterar{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    gap: 2em;
                    width: 100%;
                }

                .botoes_alterar button {
                    color:#FFF;
                    background:#3973BA;
                    padding:0.6em 2em;
                    border-radius:59px;
                    font-size: 16px;
                }

                
            }

            .input_arquivo {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 18em;
                max-height: 100%;
                background-color: #e6e4e4;
                border-radius: 2px;
                cursor: pointer;
                transition: all 0.3s;
                margin-top: 0.5em;
                background-color: #252121;

                img {
                    cursor: pointer;
                }

                .imagemPergunta {
                    max-width: 100%;
                    max-height: 100%;
                }

                input[type="file"] {
                    visibility: hidden;
                    position: absolute;
                }
            }
        }

        .input_arquivo_correcao {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 18em;
            max-height: 100%;
            background-color: #e6e4e4;
            border-radius: 2px;
            cursor: pointer;
            transition: all 0.3s;
            margin-top: 1em;
            position: relative;

            img {
                cursor: pointer;
            }


            .video_correcao {
                position: absolute;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .svg_alterar {
            width: 1.5rem;
            background-color: #3973BA;
            cursor: pointer;
            border-radius: 100%;
            margin-left: 5px;
        }

       

    }

    .modal {

        width: 30rem;

        Button {
            margin-top: 1em;
        }

    }

}

@media (max-width: 1200px) {
    .TreinoTeorico_Quiz_Novo {
        .conteudo_quiz {
            .conteudo_geral {
                .conteudo_pergunta {
                    gap: 3em;
                    .quiz {
                        width: 45%;
                    }
                }

                .arquivos {
                    width: 50%;
                }

            }
        }
    }
}

@media (max-width: 800px) {
    .TreinoTeorico_Quiz_Novo {
        .conteudo_quiz {
            .conteudo_geral {
                .conteudo_titulo {
                    margin-left: 2em;
                }

                .conteudo_pergunta {
                    flex-direction: column;
                    align-items: center;
                    gap: 1.5em;


                    .quiz {
                        width: 80%;

                        .quiz_pergunta {
                            gap: 1em;
                            width: 100%;
                        }
                        .alternativas_input {

                            input[type="checkbox"] {
                                width: 2.5em;
                                height: 2.5em;
                                right: -3.5em;
                            }
                        }
                    }
                }

                .arquivos {
                    width: 80%;
                    gap: 3em;

                }

                button {
                    align-self: flex-start;
                }

            }
        }
        .modal{
            width: 25rem;
        }
    }
}

@media (max-width: 680px) {
    .TreinoTeorico_Quiz_Novo {
        .conteudo_quiz {
            .conteudo_geral {
                .conteudo_pergunta {
                    .quiz {
                        width: 75%;

                        .quiz_pergunta {
                            width: 95%;

                            .alternativas-tipos-espaço>div {
                                margin-top: 1em;
                            }
                        }

                        .alternativas_input {

                            input[type="checkbox"] {
                                display: flex;
                                position: absolute;
                                right: -4em;
                                top: 1em;
                                appearance: none;
                                background-color: #fff;
                                margin: 0;
                                width: 3em;
                                height: 3em;
                                border: 1px solid #0D7D47;
                                border-radius: 100%;
                                cursor: pointer;
                            }
                        }
                    }

                    .arquivos {
                        width: 80%;
                        gap: 3em;

                    }

                    .input_arquivo {
                        height: 13em;
                        margin-right: 0em;
                    }

                    .input_arquivo_correcao {
                        height: 13em;
                    }


                }
            }
        }
        .modal{
            width: 100%;
        }
    }
}
    @media (max-width: 470px) {
        .TreinoTeorico_Quiz_Novo {
            .conteudo_quiz {
                .conteudo_geral {
                    .conteudo_pergunta {
                        .quiz {
                            width: 90%;

                            .quiz_pergunta {
                                .inputs-tipo-quiz {
                                    .tempo_pergunta {
                                        flex-direction: column;
                                    }
                                }
                            }
                        }
                    }

                    .arquivos {
                        width: 95%;
                    }

                }
            }
            .modal {
                width: 10rem;
                
                padding: 0;

            }
        }
    }
