.acompanhamento-aluno-admin {
  >main {
    >.inicio-usuario {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 15px;
      margin-bottom: 45px;

      >img {
        user-select: none;
        -webkit-user-drag: none;
        width: 150px;
        height: 150px;
        border-radius: 100%;
        overflow: hidden;
      } 

      >div {
        max-width: 70%;

        >h2 {
          color: #3973BA;
          font-size: 20px;
        }

        >h1 {
          color: #31332B;
          font-size: 34px;
          word-break: break-all;
          word-wrap: break-word;
          overflow-wrap: break-word;

          // @media (max-width: 650px) {
          //   text-overflow: ellipsis;
          //   overflow-wrap: normal;
          //   word-wrap: normal;
          //   word-break: keep-all;
          //   overflow: hidden;
          //   white-space:nowrap;
          // }
        }
      }

      @media (max-width: 650px) {
        flex-flow: column nowrap;

        >div {
          max-width: 100%;
        }
      }
    }


    >.cartao-entrega {
      margin-bottom: 70px;
      
      >p {
        font-size: 14px;
        user-select: none;
        font-weight: 500;
      }

      >main {
        margin-top: 7px;
      }
    }


    >.mensagem {
      margin-bottom: 100px;

      @media (max-width: 550px) {
        width: 100%;
      }
    }


    >.rotina {
      margin-bottom: 90px;

      >nav {
        display: flex;
        flex-flow: row nowrap;
        align-items: center !important;
        gap: 5px;
        margin-bottom: 10px;
        height: 36px;
        
        h2 {
          color: #31332B;
          font-size: 22px;
          user-select: none;
          padding: 0;
          margin: 0;
        }

        >img {
          width: 36px;
          height: 36px;
        }
      }

      height: fit-content;
      max-width: calc(90vw - 300px);

      overflow: hidden;
      overflow-x: auto;

      padding-right: 10px;
      padding-bottom: .5rem;

      &::-webkit-scrollbar {
        width: 10px;
        height: 7px;
      }
  
      &::-webkit-scrollbar-thumb {
        background: #b9b8b8;
        border-radius: 4px;
        cursor: default;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #b1b0b0;
      }

      @media (max-width: 1024px) {
        max-width: 90vw;
      }
    }


    >.mensagem-aluno {
      >nav {
        user-select: none;
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: flex-end;
        gap: 10px;
        margin-bottom: 30px;

        >img {
          width: 18px;
          height: 20px;
          -webkit-user-drag: none;
          cursor: pointer;
        }

        >span {
          font-size: 12px;
          font-weight: 400;
        }
      }

      >div {
        margin-bottom: 120px;

        >h1 {
          font-size: 22px;
          margin-bottom: 25px;
        }

        @media (max-width: 790px) {
          >div {
            width: 100%;
          }
        }
      }
    }
  }
}
