.treino-pratico-espirais {

    &>.espirais {
        display: flex;
        flex-flow: row wrap;
        gap: 20px;
        margin-top: 50px;
    }

    &>h1 {
        color: #A8A8A8;
    }

    @media (max-width: 600px) {
        &>h1{text-align: center;}
        &>.espirais {justify-content: center;}
    }

}