.conteudo_cla_aluno {

    &>h2 {
        color: #8A8A8A;
    }

    &>h1 {
        margin-bottom: 80px;
        color: #D3C314;
        cursor: default;
    }


    &>section {
        display: flex;
        flex-flow: column-reverse wrap;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
    }


    .playlist {
      display: flex;
      flex-direction: column;
      width: 700px;
      
      >div {  
        margin-bottom: 50px;

        >h3 {
          margin-bottom: 10px;
          cursor: default;
        }  
      }
    }


    .player {
        margin-bottom: 100px;
    }


    .container-player {
        position: relative;
    }

    .comentario {
        position: absolute;
        right: 0px;
        top: -60px;
    }



    @media (max-width: 1024px) {
        .playlist {
            width: calc(100vw - 70px);
        }
    }

    @media (max-width: 900px) {
        .container-player {
            margin-top: 50px;
        }
    }

}