

.comp-confirm {
    background: rgb(45 45 45 / 70%);
    backdrop-filter: blur(5px);

    h1 {
        font-size: 24px;
        line-height: 27px;
        margin-bottom: 18px;
        font-family: Arial, Helvetica, sans-serif;
    }
}

