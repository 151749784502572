.treino-leitura-controller {
    background-color: #121212;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    display: flex;
    justify-content: center;
    flex-direction: column;
    
    min-height: 100vh;
    font-family: 'Poppins';

}