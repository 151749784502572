.treino-teorico-perguntas-lista {

    .perguntas-lista {
        display: flex;
        flex-direction: column;
        
        width: 100%;
        
        .conteudo-perguntas-lista {
            display: flex;
            flex-direction: column;
            gap: 2.5em;

            .titulos-cla-espiral {
                & > h1 {
                    color: #31332B;
                    margin-bottom: 80px;
                }
            
                & > h2 {
                    color:#3973BA;
                }
            }

            .treino-titulo-inputs {
                display: flex;
                flex-direction: column;
                gap: 1em;
                width: 100%;
               .titulo-treino {
                   font-size: 34px;
                   font-family: 'Poppins-Bold';
               }
               .agrupamento-inputs-treino {
                    display: flex;
                    flex-direction: column;
                    gap: 1em;
                    width: 325px;
               }
            }
            .lista-perguntas {
                display: flex;
                flex-direction: column;
                gap: 1em;
                
                .titulo-container-perguntas{
                    display: flex;
                    align-items: center;
                    font-size: 24px;
                    font-family: 'Poppins-Bold';
                    gap: 0.2em;
                    img {
                        cursor: pointer;
                    }
                }
                .perguntas-dnd{
                    
                    width: 525px;
                }
            }
            .container-botao-salvar {
                display: flex;
                flex-direction: row-reverse;
                width: 98%;
            }
        }
    }
}