.conteudo-lista-admin {

    & > h1 {
        color: #31332B;
        margin-bottom: 80px;
    }

    & > h2 {
        color:#3973BA;
    }

    .titulo {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 10px;
        user-select: none;
        -webkit-user-drag: none;
        margin-bottom: 30px;

        &>img {
            width: 36px;
            height: 36px;
            padding: 3px;
            cursor: pointer;
        }
    }

    .lista {
        width: 525px;
    }

    .remover-icone path {
        stroke: #6A6A6A;
    }

}