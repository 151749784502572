.tela-resultado-leitura {
    min-height: 100vh;
    
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    
    
    
    .shake {
        & > * {
            animation: Shake .6s;
        }
    }

    .container{
        width: 100%;
        min-height: 85vh;
        
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        gap: 70px;
        padding: 100px 0px;
        
        & > div:nth-child(1) {
            width: 100%;
            
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 20px;
    
            input {
                align-self: flex-start;
                margin-left: 25px;
            }
        }
    
        & > div:nth-child(2) {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 20px;
            color: white;
        }
    }
    
    @keyframes Shake {
        10%, 90% {
            transform: translate3d(-1px, 0, 0);
        }
        
        20%, 80% {
            transform: translate3d(2px, 0, 0);
        }
        
        30%, 50%, 70% {
            transform: translate3d(-4px, 0, 0);
        }
        
        40%, 60% {
            transform: translate3d(4px, 0, 0);
        }
    }
}

@media (max-width: 720px) {
    .tela-resultado {
        padding: 0px 15px;
        .container{
            & > div:nth-child(1) {
                input {
                    align-self: inherit;
                    margin: 0px;
                }
            }
        }
    }
}
